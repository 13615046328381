























































































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import moment from "moment";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const state = reactive({
      table: false,
      loading: false,
      loaded: false,
      menu: false,
      success: false,
      error: false as boolean | number,
      isValid: false,
      certificate: false,
      certificates: [],
      messages: [],
      isEdit: false,
      disabled: false,
      smsTemplates: [],
    });

    const model = reactive({
      name: "",
      color: "",
      certificateTemplateId: "" as string | null,
      receiveConferenceMaterials: false,
      includeInStatistics: false,
      displayOwnInformation: "",
      confirmationMessageId: "",
      changeParticipantStatus: "none",
      selectToDate: "",
      smsTemplateId: "",
      receiveBadgeMessageId: "",
    });

    const color = ref<any>(null);

    const {
      participantStatusItemsSmall,
      languageItemsCurrency,
    } = useSelectItems({
      root,
    });

    const swatchStyle = computed(() => {
      return {
        backgroundColor: model.color ? model.color : "#1976D2FF",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: state.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        border: "2px solid rgba(0, 0, 0, 0.12)",
      };
    });

    const clearColor = () => {
      model.color = "";
    };

    watch(
      () => color.value,
      (newColor) => {
        model.color = newColor.hex;
      }
    );

    watch(
      () => state.certificate,
      () => {
        if (state.certificate === false) {
          model.certificateTemplateId = null;
        }
      }
    );

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      if (root.$route.params.ptgid) {
        axiosInstance
          .get(`/participant/group/${root.$route.params.ptgid}`)
          .then(({ data: { participantGroup } }) => {
            state.isEdit = true;
            state.certificate = participantGroup.receiveCertificate;

            model.name = participantGroup.name;
            model.color = participantGroup.color;

            model.receiveConferenceMaterials =
              participantGroup.receiveConferenceMaterials;
            model.includeInStatistics = participantGroup.includeInStatistics;
            model.changeParticipantStatus =
              participantGroup.changeParticipantStatus;
            model.displayOwnInformation =
              participantGroup.displayOwnInformation;
            model.certificateTemplateId =
              participantGroup.certificateTemplates[0]?.id || null;
            model.confirmationMessageId =
              participantGroup.confirmationMessage?.id || "";
            model.selectToDate = participantGroup.selectToDate
              ? moment(participantGroup.selectToDate).format("YYYY-MM-DD")
              : "";
            model.smsTemplateId = participantGroup.smsTemplate?.id || null;
            model.receiveBadgeMessageId =
              participantGroup.receiveBadgeMessage?.id || null;
            state.disabled = [
              "Wykładowca",
              "Wystawca",
              "VIP",
              "Komitet organizacyjny",
              "Recenzent",
            ].some((i) => participantGroup.name.includes(i));
          })
          .catch((error) => console.log(error))
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetchData);

    const fetchCertificates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/certificate-template/select`)
        .then(({ data }) => {
          state.certificates = data.certificateTemplates;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchCertificates);

    const fetchMessages = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/${root.$route.params.id}/other`)
        .then(({ data: { messages } }) => {
          state.messages = messages;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchMessages);

    const fetchSMS = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/sms-template`)
        .then(({ data }) => {
          state.smsTemplates = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSMS);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.misc.groupExist")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        color: model.color || undefined,
        certificateTemplateId: state.certificate
          ? model.certificateTemplateId
          : null,
        receiveConferenceMaterials: model.receiveConferenceMaterials,
        includeInStatistics: model.includeInStatistics,
        displayOwnInformation: model.displayOwnInformation || null,
        confirmationMessageId: model.confirmationMessageId || undefined,
        changeParticipantStatus: model.changeParticipantStatus,
        selectToDate: model.selectToDate || null,
        smsTemplateId: model.smsTemplateId || null,
        receiveBadgeMessageId: model.receiveBadgeMessageId || null,
        receiveCertificate: state.certificate,
      };

      state.loading = true;

      const successInfo = () =>
        root.$store.commit("snackbar/PUSH_MESSAGE", {
          id: uuid(),
          color: "success",
          message: root.$tc("layout.misc.participantGroupSaved"),
        });

      if (state.isEdit) {
        axiosInstance
          .put(`participant/group/${root.$route.params.ptgid}`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            successInfo();
            goBack("panel.event.view.participantGroup.list");
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .post(`event/${root.$route.params.id}/participant/group/`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            successInfo();
            if (action === "new") {
              model.name = "";
              model.color = "";

              model.receiveConferenceMaterials = false;
              model.includeInStatistics = false;
              model.changeParticipantStatus = "none";
              model.displayOwnInformation = "";
              model.certificateTemplateId = null;
              model.confirmationMessageId = "";
              model.selectToDate = "";
            } else if (action === "close") {
              goBack("panel.event.view.participantGroup.list");
            }
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return {
      state,
      model,
      color,
      swatchStyle,
      participantStatusItemsSmall,
      languageItemsCurrency,
      clearColor,
      onSubmit,
      rules,
    };
  },
});
